@import url('fonts/SF_PRO/stylesheet.css');
@import url('fonts/Spartan/stylesheet.css');
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply bg-basic_app_bg;
  }

  .h1 {
    @apply text-2xl;
    @apply font-bold;
  }

  .react-datepicker-time__input input {
    @apply rounded-full bg-green
  }

  input:-webkit-autofill {
    @apply bg-white;
  }
  input[type=checkbox]{
    @apply focus:ring-0 focus:ring-offset-0;
  }
  textarea:focus,
  input:focus {
    outline: none;
    @apply ring-0;
  }

  * {
    scrollbar-width: thin;
    scrollbar-color: #efe1f2 transparent;
  }

  .react-datepicker {
    background: #f5f5f7;
    width: 100% !important;
  }

  .react-datepicker__time-container {
    background: #fff;
    position: absolute;
    top: 0;
    margin-top: 30px;
    height: 30px !important;
    overflow: scroll !important;
  }

  *::-webkit-scrollbar {
    width: 20px !important;
  }

  *::-webkit-scrollbar-track {
    @apply bg-basic_app_bg rounded-[10px];
  }

  *::-webkit-scrollbar-thumb {
    @apply bg-scroll rounded-[10px] border-none;
  }

  *::-webkit-scrollbar {
    width: 6px !important;
    height: 3px !important;
  }

  .mediumScrollBar {
    scrollbar-width: thin;
    scrollbar-color: #efe1f2 transparent;
  }

  .mediumScrollBar::-webkit-scrollbar {
    width: 20px !important;
  }

  .mediumScrollBar::-webkit-scrollbar-track {
    @apply bg-basic_app_bg rounded-[10px];
  }

  .mediumScrollBar::-webkit-scrollbar-thumb {
    @apply bg-scroll rounded-[10px] border-none;
  }

  .mediumScrollBar::-webkit-scrollbar {
    width: 7px !important;
    height: 7px !important;
  }
  .select_scrollbar::-webkit-scrollbar-track {
    @apply bg-transparent;
  }


  input[type="range"]::-moz-range-progress {
    @apply bg-action;
  }


  input[type="range"].range-sm::-webkit-slider-thumb {
    @apply bg-action;
    height: 12px;
    width: 12px;
  }

  input[type="range"]::-ms-fill-lower {
    @apply bg-action;
  }

  .select_scrollbar::-webkit-scrollbar-thumb {
    @apply bg-4color rounded-[90px] border-none !h-[69px] p-[0px_2px_0px_14px];
  }
  .select_scrollbar::-webkit-scrollbar {
    width: 4px !important;
  }


  .cloud_table_scrollbar::-webkit-scrollbar-thumb {
    @apply bg-4color rounded-[90px] border-none !h-[69px] p-[0px_2px_0px_14px];
  }

  input[type=range].range_thumb {
    -webkit-appearance: none;
  }
  
  input[type=range].range_thumb::-webkit-slider-thumb {
    @apply bg-4color h-[4px] w-[69px] rounded-[90px]
  }
  
  input[type=range].range_thumb::-webkit-slider-runnable-track {
    @apply bg-4color/[0.3] h-[4px] rounded-[90px]
     
  }

  .scrollHidden {
    scrollbar-width: 0;
    scrollbar-color: transparent transparent;
  }

  .scrollHidden::-webkit-scrollbar {
    width: 0 !important;
  }

  .scrollHidden::-webkit-scrollbar {
    width: 0 !important;
    height: 0 !important;
  }
}

/* Указываем box sizing */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Убираем внутренние отступы */
ul[class],
ol[class] {
  padding: 0;
}

/* Убираем внешние отступы */
body,
h1,
h2,
h3,
h4,
p,
div,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
  box-sizing: border-box;
  font-family: 'SF Pro Display', 'Spartan', sans-serif;
  color: #1a2128;
}

/* Выставляем основные настройки по-умолчанию для body */

* body {
  font-family: 'SF Pro Display', sans-serif;
  color: #1a2128;
  margin: 0;
  min-height: 100vh;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.5;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Удаляем стандартную стилизацию для всех ul и il, у которых есть атрибут class*/
ul[class],
ol[class] {
  list-style: none;
}

/* Элементы a, у которых нет класса, сбрасываем до дефолтных стилей */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Упрощаем работу с изображениями */
img {
  max-width: 100%;
  display: block;
}

/* Указываем понятную периодичность в потоке данных у article*/
article>*+* {
  margin-top: 1em;
}

/* Наследуем шрифты для инпутов и кнопок */
input,
button,
textarea,
select {
  font: inherit;
}

.wavesurfer-handle-start {
  @apply border-action;
  border: 0.1px solid!important;
  background: transparent;
  opacity: 0;
}

.wavesurfer-handle-end {
  background: none;
  opacity: 0;
}

:root {
  --3color: #5d697e;
  --speech_analitics: #7a4487;
  --b-light: #f1f2f3;
  --width-react-datepicker: 248px;
  --height-react-datepicker: 221px;
  --height-react-datepicker-header: 36px;
  --basic-app-bg: #f5f5f7;
}

/* date-picker */
.react-datepicker__current-month,
.react-datepicker-year-header {
  display: none;
}
.react-datepicker-time__header,
.react-datepicker__header__dropdown, .react-datepicker__header__dropdown--select {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--speech_analitics);
  color: #fff;
  font-size: 15px;
  line-height: 18px;
  width: 100%;
  height: var(--height-react-datepicker-header);
  border-radius: 10px 10px 0 0;
  text-transform: capitalize;
}
.react-datepicker__month-select { 
  border: none;
  background: transparent;
  color: #fff;
  padding: 0;
  padding-right: 3px;
  text-transform: capitalize;
}
.react-datepicker__year-select{ 
  border: none;
  background: transparent;
  color: #fff;
  content: var(--basic-app-bg);
  padding: 0;
}
.react-datepicker__month-select option, .react-datepicker__year-select option{
  color:#000;
}
/* .react-datepicker__month-dropdown {
  background: var(--speech_analitics);
  position:absolute;
  opacity: 0,2;
  padding: 10px;
  max-height: 100px;
  overflow: auto;
} */
/* '' */
/* .react-datepicker__year-select .react-datepicker__year-option:first-child::before {
  content: "\25B2";
  position: absolute;
  top: 5%;
  left: 45%;
  margin: auto;
}

.react-datepicker__year-select::after {
  content: "\25BC";
  display: block;
  position: relative;
  bottom: 15%;
} */
/* '' */
/* .react-datepicker__year-dropdown {
  background: var(--speech_analitics);
  position:absolute;
  opacity: 0,2;
  padding: 10px;
  max-height: 100px;
  overflow: auto;
} */
.react-datepicker__year-option {
  color: #fff;
}
.react-datepicker__month-option {
  color: #fff;
}
/* select:not(:-internal-list-box) { */
.react-datepicker__year-select:not(:-internal-list-box) {
  max-height: 100px;
}
.react-datepicker__month-select:focus {
  box-shadow: none;
}
.react-datepicker__year-select:focus {
  box-shadow: none;
}

.react-datepicker-popper {
  z-index: 9999;
}
.react-datepicker {
  width: var(--width-react-datepicker)!important;
  min-height: var(--height-react-datepicker);
  border-radius: 10px;
  border: none;
  box-shadow: 0 8px 60px rgba(61, 74, 88, 0.1), 0 8px 25px rgba(61, 74, 88, 0.08);
  background-color: #fff;
  margin-top:15px;
}
.react-datepicker__navigation {
  z-index: 2;
  position: absolute;
  width: 6.62px;
  height: 12.2px;
  overflow: hidden;
}

.react-datepicker__navigation-icon::before {
  content: '';
  border-color: #fff;
  border-width: 1px 1px 0 0;
  display: block;
  height: 6px;
  width: 6px;
  margin-top: 3px;
}

.react-datepicker__navigation--next {
  right: 0;
  margin: 11.9px 14.22px 11.9px 0;
}

.react-datepicker__navigation--next .react-datepicker__navigation-icon::before {
  rotate: 45deg;
  margin-left: -2px;
}

.react-datepicker__navigation--previous {
  margin: 11.9px 0 11.9px 14.22px;
}

.react-datepicker__navigation--previous .react-datepicker__navigation-icon::before {
  rotate: -135deg;
  margin-left: 2px;
}
 /* aaasa */
.react-datepicker--time-only {
  width: calc(var(--width-react-datepicker) / 2);
  height: var(--height-react-datepicker);
}

.react-datepicker__time-container {
  height: 100%;
}

.react-datepicker__time {
  height: calc(100% - var(--height-react-datepicker-header));
  overflow-x: hidden;
  overflow-y: auto;
}

.react-datepicker__triangle {
  width: 18px;
  height: 18px;
  left: 50% !important;
  transform: translate(-50%, -6.73px) rotate(45deg) !important;
  background: var(--speech_analitics);
}

.react-datepicker__header {
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  background-color: transparent;
  padding-top: 0;
  border: none;
}

.react-datepicker__day-names {
  width: 212px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 0 11px 0;
}

.react-datepicker__day-names .react-datepicker__day-name {
  text-transform: uppercase;
  width: 20px;
  height: 13px;
  font-size: 12px;
  font-weight: 600;
  line-height: 13px;
  letter-spacing: -0.025em;
  color: var(--3color);
  margin: 0 12px 0 0;
}

.react-datepicker__day-names .react-datepicker__day-name:last-child,
.react-datepicker__day-names .react-datepicker__day-name:nth-child(6) {
  color: var(--speech_analitics);
}

.react-datepicker__month {
  display: flex;
  flex-direction: column;
  margin: 0 19px 0 17px;
  padding: 12px 0 0 0;
  border-top: 1px solid var(--b-light);
}
.react-datepicker-time__caption {
  display: none;
}
.react-datepicker-time__input {
  display: flex;
  width: 100%;
  padding-right: 40px;
  padding-left: 40px;
  outline-width: 3px!important;
  /* outline: none!important; */
  padding-top: 10px;
  padding-bottom: 10px;
  outline-color: var(--speech_analitics)!important;
  border: none!important;
  --tw-ring-shadow: 0 0 #000 !important;
}

.react-datepicker__week {
  display: flex;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 20px;
  color: var(--3color);
  font-size: 12px;
  font-weight: 600;
  line-height: 13.12px;
  letter-spacing: -0.025em;
  margin: 0 6px 13px 6px;
  cursor: pointer;
  border-radius: 10px;
  transition: border 150ms;
}

.react-datepicker__day-name:first-child,
.react-datepicker__day:first-child,
.react-datepicker__time-name:first-child {
  margin-left: 0;
}

.react-datepicker__day-name:last-child,
.react-datepicker__day:last-child,
.react-datepicker__time-name:last-child {
  margin-right: 0;
}

.react-datepicker__day-name {
  cursor: default;
}

.react-datepicker__day:hover {
  border: 1px solid var(--speech_analitics);
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  border: 1px solid var(--speech_analitics);
  background-color: transparent;
}

.react-datepicker__time-list-item {
  text-align: center;
  color: var(--3color);
  margin: 0 5px;
  border-bottom: 1px solid var(--b-light);
  cursor: pointer;
  transition: background-color 150ms;
}

.react-datepicker__time-list-item:hover {
  background-color: var(--basic-app-bg);
}

.react-datepicker__time-list-item--selected {
  color: var(--speech_analitics);
}
.react-datepicker__aria-live {
  display: none;
}
.react-datepicker__day--disabled {
  color: rgb(203, 213, 225)!important;
  pointer-events: none!important;
}
 /* datepicker */

*:focus {
  outline: none;
}

/* apexcharts styles */
/* .apexcharts-toolbar {
  position: absolute;
  top: -123px !important;
  right: 55px !important;
  display: inline-flex;
  align-items: center;
   gap: 3px;
} */
.apexcharts-legend {
  justify-content: center!important;
  /*top:50px!important;*/
  max-height: 80%!important;
  /*padding-bottom: 20px!important;*/
  /*padding-top: 60px!important;*/
  /*margin-top: 40px!important; ;*/
}
.apexcharts-menu {
  border-radius: 10px !important;
}

.apexcharts-menu-item {
  border-radius: 10px;
  padding-left: 0 !important;
}

.apexcharts-menu-item:hover {
  color: #7a4487;
  padding-left: 0 !important;
}

/*.apexcharts-legend {*/
/*  position: absolute !important;*/
/*}*/

.blocklyFlyoutScrollbar {
  z-index: 30;
  display: none !important;
}

.select__input:focus {
  --tw-ring-color: transparent !important;
}
.form-check-input[type=checkbox]:indeterminate {
    @apply bg-action border-action;
    background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 20 20%27%3e%3cpath fill=%27none%27 stroke=%27%23fff%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%273%27 d=%27M6 10h8%27/%3e%3c/svg%3e');

}
.tippy-box[data-theme~='tomato'] {
  @apply bg-b_light;
}

/*apex chart reset toolbar icon */
.apexcharts-datalabels {
  width: 100%!important;
}
.apexcharts-bar-area  {
  cursor: pointer;
}
.apexcharts-pie-area  {
  cursor: pointer;
}
.apexcharts-reset-icon{
  margin-left: 0!important;
}
.apexcharts-pan-icon{
  left: 0!important;
}
.apexcharts-zoomout-icon{
  margin-right: 0!important;
}
.wavesurfer-region {
  @apply !cursor-default
}
.wavesurfer-handle-start {
  @apply !cursor-default
}
.wavesurfer-handle-end {
  @apply !cursor-default
  }

/* table scroll */
/********** Range Input Styles **********/
/*Range Reset*/
.tableScroll[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  cursor: pointer;
  outline: none;
}

/***** table scroll *****/
/* slider track */
.tableScroll[type="range"]::-webkit-slider-runnable-track {
  @apply bg-transparent;/*background-color: #053a5f;*/
  border-radius: 0.5rem;
  height: 0.5rem;
}

/* slider thumb */
.tableScroll[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  /*margin-top: -12px; /* Centers thumb on the track */
  background-color: transparent;
  /*custom styles*/
  @apply w-0 h-0;
  height: 0;
  width: 0;
}
.tableScroll[type="range"]:focus::-webkit-slider-thumb {
  border: 0;
  @apply w-0 h-0;
  outline: 0;
  outline-offset: 0;
  background-color: transparent;
}

/******** Firefox styles ********/
.tableScroll[type=range]::-moz-range-track {
  height: 5px;
  border: none;
  border-radius: 3px;
  background-color: transparent;
}

.tableScroll[type=range]::-moz-range-thumb {
  border: none;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background-color: transparent!important;
}
input[type="range"]::-moz-range-progress {
  background-color: transparent;
}
textarea {
  resize: none;
}

.hideScrollAllChrome::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hideScrollAll {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}